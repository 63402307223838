import {
  ChooseSettings,
  ConditionAffectTypes,
  Conditions,
  EnergyType,
  EnergyTypeSecond,
  InputSettings,
  InputType,
  Question,
  QuestionTypes,
  SliderSettings,
  Stage,
  TitleType,
  BackendNames,
} from "./types";

export const stageTitles: string[] = [
  "Mon Potentiel d'économie",
  "Mon budget personnalisé",
  "Ma prise de contact WIT",
];

export const standardStages: Stage[] = [
  {
    label: "Superficie",
    number: 0,
  },
  {
    label: "Montant de votre facture annuelle",
    number: 2,
  },
  {
    label: "Chauffage",
    number: 3,
  },
  {
    label: "Climatisation, refroidissement",
    number: 4,
  },
  {
    label: "Eau chaude sanitaire",
    number: 5,
  },
  {
    label: "Éclairage",
    number: 6,
  },
  {
    label: "Cartographie de vos consommations",
    number: 7,
  },
  {
    label: "Mieux vous connaître",
    number: 8,
  },
  {
    label: "Définissez votre budget",
    number: 9,
  },
];

export const secondStages: Stage[] = [
  {
    label: "Chauffage",
    number: 10,
  },
  {
    label: "Climatisation",
    number: 11,
  },
  {
    label: "Ventilation Mécanique Contrôlée (VMC)",
    number: 12,
  },
  {
    label: "Eau chaude sanitaire",
    number: 13,
  },
  {
    label: "Eclairage",
    number: 14,
  },
  {
    label: "Votre entreprise",
    number: 15,
  },
];

export const Questions: Question[] = [
  {
    title: "Superficie",
    question: "Quelle est la superficie de votre bâtiment ?",
    subQuestion: `Plus de 3 000 m<sup>2</sup> ? Contactez notre service commercial ici: <a class='mail-link' href="mailto:contact@wit-iko.fr">contact@wit-iko.fr</a>`,
    specialText: "Plus d'un bâtiment ? Évaluez-les un par un",
    type: QuestionTypes.SLIDER,
    settings: <SliderSettings>{
      min: 1000,
      max: 3000,
      step: 100,
      unit: "m<sup>2</sup>",
      defaultValue: 2000,
    },
    info: "Vous trouverez la surface de vos bâtiments sur les baux locatifs ou les titres de propriétés.<br>Une réponse approchante est suffisante pour nos calcus.",
    prev: false,
    next: true,
    selectedStage: 0,
    answer: "2000",
    number: 0,
    titleType: TitleType.ECONOMY,
    backend_value: BackendNames.AREA,
  },
  {
    title: "Superficie - Superficie climatisée",
    question:
      "Que représente, en pourcentage, la surface du bâtiment climatisée/chauffée ?",
    type: QuestionTypes.SLIDER,
    settings: <SliderSettings>{
      min: 0,
      max: 100,
      step: 10,
      unit: "%",
      defaultValue: 50,
    },
    prev: true,
    next: true,
    selectedStage: 0,
    answer: "",
    number: 1,
    titleType: TitleType.ECONOMY,
    backend_value: BackendNames.PROPORTION,
  },
  {
    title: "Montant de votre facture annuelle",
    question:
      "Quel est le montant total HT de vos charges annuelles en énergie(s) ?",
    type: QuestionTypes.INPUT,
    settings: <InputSettings>{
      label: "Montant total HT",
      required: false,
      optional: true,
      unit: "€",
      type: InputType.VALUE,
      answer: "",
    },
    info: "Il s'agit de la somme de toutes vos dépenses énergétiques sur la dernière année, toutes énergies confondues (électricité, gaz, bois, fioul, etc.).",
    prev: true,
    next: true,
    selectedStage: 2,
    answer: "",
    number: 2,
    backend_name: "amount",
    titleType: TitleType.ECONOMY,
    backend_value: BackendNames.PRICE,
  },
  {
    title: "Type d'énergie consommée",
    question: "Quelles sont les énergies utilisées ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Gaz",
          value: "gaz",
        },
        {
          text: "Electricité",
          value: "electricité",
        },
        {
          text: "Fioul",
          value: "fioul",
        },
        {
          text: "Autre",
          value: "autre",
        },
      ],
      required: true,
      multiOption: true,
    },
    prev: true,
    next: true,
    selectedStage: 2,
    answer: "",
    number: 3,
    titleType: TitleType.ECONOMY,
  },
  {
    title: "Chauffage",
    question:
      "Le chauffage de votre bâtiment est-il programmé en fonction des horaires d'occupation ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La programmation horaire est généralement gérée par un système de Gestion Technique du Bâtiment ou par des thermostats électroniques. Ces dispositifs permettent de modifier le mode de chauffage en fonction des horaires afin de réaliser des économies sur l'un des postes les plus consommateurs d'énergie.",
    prev: true,
    next: true,
    selectedStage: 3,
    answer: "",
    number: 4,
    backend_name: "isHeatingScheduledBasedOnOccupancy",
    energyType: EnergyType.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chauffage",
    question:
      "La programmation horaire du chauffage de votre bâtiment se fait-elle par pièce ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.NO_CONDITION,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La programmation horaire est généralement gérée par un système de Gestion Technique du Bâtiment ou par des thermostats électroniques. Ces dispositifs permettent de modifier le mode de chauffage en fonction des horaires afin de réaliser des économies sur l'un des postes les plus consommateurs d'énergie.",
    prev: true,
    next: true,
    selectedStage: 3,
    answer: "",
    number: 5,
    backend_name: "isRoomByRoomHeatingScheduled",
    energyType: EnergyType.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chauffage",
    question: "Le chauffage est-il activé avec des détecteurs de présence ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.NO_CONDITION,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Les systèmes intelligents, équipés d'un ou plusieurs détecteurs de présence, permettent d'arrêter la chauffe lorsque vous êtes absents, donc de diminuer ce temps de chauffe et les déperditions d'énergie.",
    prev: true,
    next: true,
    selectedStage: 3,
    answer: "",
    number: 6,
    backend_name: "isHeatingActivatedByPresenceSensors",
    energyType: EnergyType.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chauffage",
    question:
      "Disposez-vous d'une solution de gestion technique de bâtiment (GTB) pour gérer le système de chauffage ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.NO_CONDITION,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La Gestion Technique du Bâtiment (GTB) est un système qui assure de manière centralisée le pilotage et la supervision de l'ensemble des installations techniques (ou lots) des bâtiments tertiaires. Elle permet d'avoir une vision globale du bâtiment, en termes de confort, de sécurité et de sûreté.",
    prev: true,
    next: true,
    selectedStage: 3,
    answer: "",
    number: 7,
    backend_name: "hasCentralizedHeatingManagementSolution",
    energyType: EnergyType.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Mieux vous connaître",
    topInfo:
      "Vous recevrez le récapitulatif de vos réponses à notre questionnaire sur votre adresse e-mail. L'adresse e-mail permettra également à l'équipe commerciale de WIT de vous contracter si vous demandez à être mis en relation.",
    type: QuestionTypes.INPUTS,
    userInfo: true,
    settings: <InputSettings[]>[
      {
        label: "Votre nom",
        required: true,
        type: InputType.TEXT,
        name: "name",
        answer: "",
        summary_text: "Nom",
        backend_name: "lastName",
      },
      {
        label: "Votre prénom",
        required: true,
        type: InputType.TEXT,
        name: "firstname",
        answer: "",
        summary_text: "Prénom",
        backend_name: "firstName",
      },
      {
        label: "Votre adresse e-mail",
        required: true,
        type: InputType.EMAIL,
        name: "email",
        answer: "",
        summary_text: "Adresse e-mail",
        backend_name: "email",
      },
      {
        label:
          "J'accepte les Conditions Générales d'Utilisation et d'être contacté par l'équipe commerciale de WIT SA si je demande à être mis en relation",
        required: true,
        type: InputType.CHECKBOX,
        name: "checkbox",
        answer: false,
        summary_text: "Conditions Générales d'Utilisation",
        answer_text: {
          true: "Acceptées",
          false: "Refusées",
        },
      },
    ],
    prev: true,
    next: true,
    selectedStage: 8,
    number: 22,
    titleType: TitleType.USER_INFO,
  },
  {
    title: "Climatisation, refroidissement",
    question: "Avez-vous un système de climatisation ?",
    type: QuestionTypes.CHOOSE,
    canBeOmitted: true,
    conditionForOthers: {
      type: ConditionAffectTypes.VALUE_DIFFERENT_THAN,
      value: "Oui",
    },
    conditionAffects: [
      {
        type: ConditionAffectTypes.STAGE_NOT_AVAILABLE,
        number: 11,
      },
    ],
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La programmation horaire des climatisations peut se faire convecteur par convecteur en utilisant la télécommande, ou par un système centralisé, souvent proposé par le fabricant (site web, application mobile), gérant les horaires d'allumage et d'extinction. L'air conditionné est, avec le chauffage, très énergivore. Nous évaluons ici les marges de progression que nous pouvons réaliser ensemble.",
    prev: true,
    next: true,
    selectedStage: 4,
    answer: "",
    number: 8,
    backend_name: "hasAirConditioningSystem",
    energyType: EnergyType.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation, refroidissement",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 8,
      value: "Oui",
    },
    question:
      "La climatisation de votre bâtiment est-elle programmée en fonction des horaires d'occupation de chaque pièce ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La programmation horaire de la climatisation peut se faire convecteur par convecteur ou via un système centralisé, pour gérer les horaires d'allumage et d'extinction. L'air conditionné est, comme le chauffage, un poste très énergivore. Nous évaluons ici les marges de progression que nous pouvons réaliser ensemble.",
    prev: true,
    next: true,
    selectedStage: 4,
    answer: "",
    number: 9,
    backend_name: "isAirConditioningScheduledBasedOnOccupancy",
    energyType: EnergyType.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation, refroidissement",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 8,
      value: "Oui",
    },
    question:
      "La climatisation est-elle activée avec des détecteurs de présence ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La programmation horaire des climatisations peut se faire convecteur par convecteur en utilisant la télécommande, ou par un système centralisé, souvent proposé par le fabricant (site web, application mobile), gérant les horaires d'allumage et d'extinction. L'air conditionné est, avec le chauffage, très énergivore. Nous évaluons ici les marges de progression que nous pouvons réaliser ensemble.",
    prev: true,
    next: true,
    selectedStage: 4,
    answer: "",
    number: 10,
    backend_name: "isAirConditioningActivatedByPresenceSensors",
    energyType: EnergyType.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation, refroidissement",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 10,
      value: "Non",
    },
    secondCondition: {
      question: 8,
      value: "Oui",
    },
    question:
      "Disposez-vous d'une solution de gestion technique de bâtiment (GTB) pour gérer le système de climatisation-refroidissement ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "La Gestion Technique du Bâtiment (GTB) est un système qui assure de manière centralisée le pilotage et la supervision de l'ensemble des installations techniques (ou lots) des bâtiments tertiaires. Elle permet d'avoir une vision globale du bâtiment, en termes de confort, de sécurité et de sûreté.",
    prev: true,
    next: true,
    selectedStage: 4,
    answer: "",
    number: 11,
    backend_name: "hasCentralizedAirConditioningManagementSolution",
    energyType: EnergyType.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question:
      "Votre bâtiment dispose-t-il d'un système de production d'eau chaude sanitaire ?",
    type: QuestionTypes.CHOOSE,
    canBeOmitted: true,
    conditionAffects: [
      {
        type: ConditionAffectTypes.STAGE_NOT_AVAILABLE,
        number: 13,
      },
    ],
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Plusieurs systèmes sont possibles pour la production d'eau chaude sanitaire. Les différences se trouvent essentiellement dans la ressource énergétique utilisée : le chauffe-eau traditionnel (électrique ou gaz) à accumulation ou instantané, le chauffe-eau thermodynamique avec une pompe à chaleur dédiée, les panneaux solaires thermiques qui transforment le rayonnement solaire en chaleur, les chaudières à gaz, fioul ou bois, la pompe à chaleur qui chauffe et produit de l'eau chaude.",
    prev: true,
    next: true,
    selectedStage: 5,
    answer: "",
    number: 12,
    backend_name: "hasHotWater",
    energyType: EnergyType.HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question: "Votre bâtiment est-il équipé de chauffe-eau ?",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 12,
      value: "Oui",
    },
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "On appelle chauffe-eau, également nommé cumulus, tout équipement ou appareil, industriel ou domestique, permettant de produire de l'eau chaude sanitaire. Le rôle du chauffe-eau est de chauffer et de maintenir l'eau à la température souhaitée, ce qui en fait l'un des plus gros consommateurs d'énergie dans un bâtiment. Un bon entretien de votre chauffe-eau permet de réaliser des économies au quotidien.",
    prev: true,
    next: true,
    selectedStage: 5,
    answer: "",
    number: 13,
    energyType: EnergyType.HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question:
      "Utilisez-vous un système de programmation horaire pour le chauffage de votre ballon d'eau chaude ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 12,
      value: "Oui",
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Vérifiez votre ou vos cumulus (ballons d'eau chaude) afin de vous assurer s'ils sont équipés ou non d'un système de programmation horaire ainsi que d'un thermostat contrôlant la température d'eau chaude. La maîtrise de la programmation de la production d'eau chaude sanitaire peut permettre de réaliser des économies substantielles.",
    prev: true,
    next: true,
    selectedStage: 5,
    answer: "",
    number: 14,
    backend_name: "isHeatingScheduledForHotWaterTank",
    energyType: EnergyType.HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question:
      "Votre production d'eau chaude est-elle optimisée en fonction de la température du ballon ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 12,
      value: "Oui",
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Vérifiez votre ou vos cumulus (ballon d'eau chaude) afin de vous assurez qu'ils sont équipés ou non d'un système de programmation horaire ainsi que d'un thermostat contrôlant la température d'eau chaude. L'eau chaude sanitaire consomme beaucoup d'énergie. La maîtrise de la programmation de la production d'eau chaude permet des économies substantielles.",
    prev: true,
    next: true,
    selectedStage: 5,
    answer: "",
    number: 15,
    backend_name: "isHotWaterProductionOptimizedBasedOnTankTemperature",
    energyType: EnergyType.HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Éclairage",
    question:
      "Est-ce que les éclairages de votre bâtiment sont automatiquement éteints la nuit ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Même avec l'avènement des lampes à LED, l'éclairage continue d'être consommateur d'énergie, souvent inutilement (bureaux éclairés la nuit, couloirs éclairés en continu sans détection de présence, par exemple). Là encore, des économies sont réalisables.",
    prev: true,
    next: true,
    selectedStage: 6,
    answer: "",
    number: 16,
    backend_name: "areLightsAutomaticallyTurnedOffAtNight",
    energyType: EnergyType.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Éclairage",
    question: "Votre éclairage est-il pilotable en fonction d'un planning ?",
    condition: Conditions.NO_CONDITION,
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Même avec l'avènement des lampes à LED, l'éclairage continue d'être consommateur d'énergie, souvent inutilement (bureaux éclairés la nuit, couloirs éclairés en continu sans détection de présence, par exemple). Là encore, des économies sont réalisables.",
    prev: true,
    next: true,
    selectedStage: 6,
    answer: "",
    number: 17,
    backend_name: "isLightingControllableBySchedule",
    energyType: EnergyType.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Éclairage",
    question: "Votre éclairage est-il pilotable en fonction de la luminosité ?",
    condition: Conditions.NO_CONDITION,
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Même avec l'avènement des lampes à LED, l'éclairage continue d'être consommateur d'énergie, souvent inutilement (bureaux éclairés la nuit, couloirs éclairés en continu sans détection de présence, par exemple). Là encore, des économies sont réalisables.",
    prev: true,
    next: true,
    selectedStage: 6,
    answer: "",
    number: 18,
    backend_name: "isLightingControllableByBrightness",
    energyType: EnergyType.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Éclairage",
    question: "Votre éclairage est-il pilotable en fonction de la présence ?",
    condition: Conditions.NO_CONDITION,
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
        {
          text: "Je ne sais pas",
          value: "Je ne sais pas",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Même avec l'avènement des lampes à LED, l'éclairage continue d'être consommateur d'énergie, souvent inutilement (bureaux éclairés la nuit, couloirs éclairés en continu sans détection de présence, par exemple). Là encore, des économies sont réalisables.",
    prev: true,
    next: true,
    selectedStage: 6,
    answer: "",
    number: 19,
    backend_name: "isLightingControllableByPresence",
    energyType: EnergyType.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Cartographie de vos consommations",
    question:
      "Savez-vous comment sont réparties les consommations énergétiques de votre bâtiment ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Une meilleure connaissance de la consommation d'énergie, et la sensibilisation du personnel permettent une meilleur maîtrise budgétaire de l'énergie.",
    prev: true,
    next: true,
    selectedStage: 7,
    answer: "",
    number: 20,
    backend_name: "isAwareOfEnergyConsumptionDistribution",
    energyType: EnergyType.ENERGY_CONSUMPTION,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Cartographie de vos consommations",
    question:
      "Mettez-vous en place une communication sur vos consommations énergétiques à destination de vos collaborateurs ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Selon l'Observatoire de l'Immobilier Durable (OID), la sensibilisation des occupants aux bonnes pratiques permet d'atteindre un objectif de 10 % à 20 % de consommations en moins.",
    prev: true,
    next: true,
    selectedStage: 7,
    answer: "",
    number: 21,
    backend_name: "implementsCommunicationOnEnergyConsumptionForEmployees",
    energyType: EnergyType.ENERGY_CONSUMPTION,
    titleType: TitleType.BUDGET,
  },
];

export const QuestionsSecond: Question[] = [
  {
    title: "Chauffage",
    question: "Comment chauffez-vous votre bâtiment ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Chaudière",
          value: "Chaudière",
        },
        {
          text: "Pompe à chaleur",
          value: "Pompe à chaleur",
        },
        {
          text: "Split indépendant",
          value: "Split indépendant",
        },
        {
          text: "Radiateurs électriques",
          value: "Radiateurs électriques",
        },
      ],
      required: true,
      multiOption: true,
    },
    prev: false,
    next: true,
    selectedStage: 10,
    answer: "",
    number: 23,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chaudière",
    question: "Combien de chaudières avez-vous ?",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Chaudière",
    },
    type: QuestionTypes.NUMBER,
    settings: <InputSettings>{
      label: "",
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Prenez en compte tout type d'énergie confondu : bois, fioul, gaz, etc.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 24,
    backend_name: "Boiler",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chaudière",
    question: "Combien de circuits (ou de départs) avez-vous ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Chaudière",
    },
    settings: <InputSettings>{
      label: "",
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Un circuit (ou un départ) se réfère au point où l'eau chaude produite par la chaudière est distribuée vers le système de chauffage du bâtiment. C'est le point à partir duquel l'eau chaude est acheminée vers les radiateurs, les planchers chauffants ou d'autres dispositifs de diffusion de chaleur.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 25,
    backend_name: "RegulatedCircuits",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Chaudière",
    question: "Combien d'équipements de chauffage avez-vous ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Chaudière",
    },
    settings: <InputSettings>{
      label: "",
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Prenez en compte ici tout type d'équipement de chauffage: radiateurs à eau, ventilo-convecteurs, plancher chauffant, aérothermes, centrale de traitement d'air",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 26,
    backend_name: "EmittersHeatingVentilation",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Pompe à chaleur",
    question: "Avez-vous une pompe à chaleur de type air/eau ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Pompe à chaleur",
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Une Pompe à Chaleur Air/Eau  utilise l'énergie présente dans l'air extérieur pour chauffer un fluide caloporteur (généralement de l'eau) qui est ensuite utilisé pour chauffer votre bâtiment et/ou produire de l'eau chaude sanitaire.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "",
    number: 27,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Pompe à chaleur",
    question:
      "Combien de pièces sont chauffées par votre pompe à chaleur air/eau ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 27,
      value: "Oui",
    },
    settings: <InputSettings>{
      label: "",
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Entrez ici la somme de toutes les pièces pouvant être chauffées par votre PAC air/eau.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 28,
    backend_name: "HeatPumpAirWater",
    energyType: EnergyType.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Pompe à chaleur",
    question: "Avez-vous une pompe à chaleur de type air/air ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Pompe à chaleur",
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Une Pompe à Chaleur Air/Air utilise l'énergie présente dans l'air extérieur pour chauffer ou refroidir l'air qui est ensuite distribué à l'intérieur de votre bâtiment. Il s'agit d'une pompe à chaleur aérothermique, c'est-à-dire qu'elle utilise l'énergie thermique présente dans l'air pour effectuer le transfert de chaleur. Elle est d'un seul bloc, sinon on parle de split.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "",
    number: 29,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Pompe à chaleur",
    question:
      "Combien de pièces sont chauffées par votre pompe à chaleur air/air ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 29,
      value: "Oui",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Entrez ici la somme de toutes les pièces pouvant être chauffées par votre PAC air/air.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 30,
    backend_name: "HeatPumpAirAir",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Split indépendant",
    question: "Combien d'unités intérieures comporte votre split indépendant ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Split indépendant",
    },
    settings: <InputSettings>{
      label: "",
      // required: true,
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Prenez en compte ici tout type d'équipement : ventilo-convecteurs de plafond et unités murales.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 31,
    backend_name: "EmittersHeatPumpAirAir",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Radiateurs électriques",
    question: "Nombre de radiateurs électriques",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 23,
      value: "Radiateurs électriques",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Comptez tous les radiateurs dont votre bâtiment est équipé. Si une pièce comporte plusieurs radiateurs, prenez-les tous en compte.",
    prev: true,
    next: true,
    selectedStage: 10,
    answer: "0",
    number: 32,
    backend_name: "ElectricRadiators",
    energyType: EnergyTypeSecond.HEATING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation",
    question: "Comment climatisez-vous votre bâtiment ?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED_AND_NOT,
    conditionValue: {
      question: 8,
      value: "Non",
      which: 1,
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Pompe à chaleur Air/Air",
          value: "Pompe à chaleur Air/Air",
        },
        {
          text: "Pompe à chaleur Air/Eau",
          value: "Pompe à chaleur Air/Eau",
        },
        {
          text: "Split indépendant",
          value: "Split indépendant",
        },
      ],
      required: true,
      multiOption: true,
    },
    // info: "Vous trouverez le récapitulatif de vos dépenses HT dans votre bilan comptable ou sur la dernière facture de vos fournisseurs d'énergie de l'exercice passé . Faites la somme de toutes les dépenses HT de votre dernier exercice, toutes énergies confondues (électricité, gaz, bois, fioul, etc.).",
    prev: true,
    next: true,
    selectedStage: 11,
    answer: "",
    defaultAnswer: "",
    number: 33,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation",
    question:
      "Combien de pièces sont climatisées par votre pompe à chaleur air/air ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 33,
      value: "Pompe à chaleur Air/Air",
    },
    settings: <InputSettings>{
      label: "",
      // required: true,
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
      // placeholder: "Montant total HT de vos charges annuelles en énergie(s)",
    },
    info: "Entrez ici la somme de toutes les pièces pouvant être climatisées par votre PAC air/air.",
    prev: true,
    next: true,
    selectedStage: 11,
    answer: "0",
    defaultAnswer: "0",
    number: 34,
    backend_name: "HeatPumpAirAir",
    energyType: EnergyTypeSecond.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation",
    question:
      "Combien de pièces sont climatisées par votre pompe à chaleur air/eau ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 33,
      value: "Pompe à chaleur Air/Eau",
    },
    settings: <InputSettings>{
      label: "",
      // required: true,
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
      // placeholder: "Montant total HT de vos charges annuelles en énergie(s)",
    },
    info: "Entrez ici la somme de toutes les pièces pouvant être climatisées par votre PAC air/eau.",
    prev: true,
    next: true,
    selectedStage: 11,
    answer: "0",
    defaultAnswer: "0",
    number: 35,
    backend_name: "HeatPumpAirWater",
    energyType: EnergyTypeSecond.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Climatisation",
    question: "Combien d'unités intérieures comporte votre split indépendant ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 33,
      value: "Split indépendant",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Prenez en compte ici tout type d'équipement : ventilo-convecteurs de plafond et unités murales.",
    prev: true,
    next: true,
    selectedStage: 11,
    answer: "0",
    defaultAnswer: "0",
    number: 36,
    backend_name: "EmittersHeatPumpAirAir",
    energyType: EnergyTypeSecond.AIR_CONDITIONER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Ventilation Mécanique Contrôlée (VMC)",
    question:
      "Votre bâtiment dispose-t-il d'une Ventilation Mécanique Contrôlée ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Une Ventilation Mécanique Contrôlée (ou VMC), est un système de ventilation utilisé dans les bâtiments pour renouveler l'air intérieur. Son objectif principal est d'évacuer l'air vicié, contenant potentiellement des polluants, des odeurs et de l'humidité, tout en introduisant de l'air frais de l'extérieur.",
    prev: true,
    next: true,
    selectedStage: 12,
    answer: "",
    number: 37,
    backend_name: "Ventilation",
    energyType: EnergyTypeSecond.VMC,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question:
      "L'eau chaude sanitaire est-elle produite de manière centralisée pour tout le(s) bâtiment(s)?",
    type: QuestionTypes.CHOOSE,
    condition: Conditions.QUESTION_ANSWERED_AND_NOT,
    conditionValue: {
      question: 12,
      value: "Non",
      which: 1,
    },
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "L'Eau Chaude Sanitaire peut être produite de manière centralisée dans un bâtiment ou un ensemble de bâtiments à l'aide de différents systèmes de production d'eau chaude comme: Chaudière, Échangeurs de chaleur avec réseaux de chauffage central, Pompes à chaleur, Systèmes solaires thermiques, Chauffe-eau instantané centralisé, etc.",
    prev: true,
    next: true,
    selectedStage: 13,
    answer: "",
    defaultAnswer: "Non",
    number: 38,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question: "Quel est le type de production ?",
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 38,
      value: "Oui",
    },
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Echangeur avec ballon",
          value: "Oui",
        },
        {
          text: "Echangeur sans ballon",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Dans un système avec échangeur avec ballon, l'eau chaude produite par l'échangeur est stockée dans ce ballon. Dans un système avec échangeur sans ballon, l'eau chaude est produite directement à la demande, sans être stockée.",
    prev: true,
    next: true,
    selectedStage: 13,
    answer: "",
    defaultAnswer: "Non",
    number: 39,
    backend_name: "DHWExchange",
    energyType: EnergyTypeSecond.DOMESTIC_HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eau chaude sanitaire",
    question: "Combien avez-vous de cumulus ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 38,
      value: "Non",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Un cumulus fait référence à un chauffe-eau électrique avec réservoir, souvent appelé \"cumulus électrique\" ou \"ballon d'eau chaude\". Il s'agit d'un dispositif électrique qui chauffe l'eau et la stocke dans un réservoir, la rendant ainsi disponible pour une utilisation ultérieure.",
    prev: true,
    next: true,
    selectedStage: 13,
    answer: "0",
    defaultAnswer: "0",
    number: 40,
    backend_name: "Cumulus",
    energyType: EnergyTypeSecond.DOMESTIC_HOT_WATER,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eclairage",
    question:
      "Connaissez-vous le nombre de zone d'éclairage de votre bâtiment ?",
    type: QuestionTypes.CHOOSE,
    settings: <ChooseSettings>{
      options: [
        {
          text: "Oui",
          value: "Oui",
        },
        {
          text: "Non",
          value: "Non",
        },
      ],
      required: true,
      multiOption: false,
    },
    info: "Une zone d'éclairage fait référence à une section spécifique qui est délimitée pour être éclairée par un ou plusieurs dispositifs d'éclairage. Une zone d'éclairage peut être définie en fonction de l'aménagement de l'espace, de la fonctionnalité des différentes zones dans le bâtiment.",
    prev: true,
    next: true,
    selectedStage: 14,
    answer: "",
    number: 41,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eclairage",
    question: "Combien de zones sont présentes ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 41,
      value: "Oui",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    prev: true,
    next: true,
    selectedStage: 14,
    answer: "0",
    number: 42,
    backend_name: "LightingZone",
    energyType: EnergyTypeSecond.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Eclairage",
    question: "Combien avez-vous d'armoires électriques ?",
    type: QuestionTypes.NUMBER,
    condition: Conditions.QUESTION_ANSWERED,
    conditionValue: {
      question: 41,
      value: "Non",
    },
    settings: <InputSettings>{
      label: "",
      optional: false,
      unit: "",
      type: InputType.VALUE,
      answer: "0",
    },
    info: "Les armoires électriques sont en général présentes dans les locaux techniques, les couloirs, les sous-sols, les dégagements dans les étages, etc. Elles sont dans des locaux ou des placards dédiés.",
    prev: true,
    next: true,
    selectedStage: 14,
    answer: "0",
    number: 43,
    backend_name: "CabinetLighting",
    energyType: EnergyTypeSecond.LIGHTNING,
    titleType: TitleType.BUDGET,
  },
  {
    title: "Votre entreprise",
    type: QuestionTypes.INPUTS,
    userInfo: true,
    settings: <InputSettings[]>[
      {
        label: "Nom de votre entreprise",
        required: true,
        type: InputType.TEXT,
        name: "companyName",
        answer: "",
        summary_text: "Nom de votre entreprise",
        backend_name: "companyName",
      },
      {
        label: "Votre fonction dans l'entreprise",
        required: true,
        type: InputType.TEXT,
        name: "job",
        answer: "",
        summary_text: "Votre fonction dans l'entreprise",
        backend_name: "job",
      },
      {
        label: "Votre secteur d'activité",
        required: true,
        type: InputType.SELECTOR,
        name: "businessSector",
        answer: "",
        summary_text: "Votre secteur d'activité",
        backend_name: "businessSector",
        selectorOptions: [
          {
            text: "Agriculture, sylviculture et pêche",
            value: "Agriculture, sylviculture et pêche",
          },
          { text: "Industries extractives", value: "Industries extractives" },
          {
            text: "Industrie manufacturière",
            value: "Industrie manufacturière",
          },
          {
            text: "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
            value:
              "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
          },
          {
            text: "Production et distribution d'eau, assainissement, gestion des déchets et dépollution",
            value:
              "Production et distribution d'eau, assainissement, gestion des déchets et dépollution",
          },
          { text: "Construction", value: "Construction" },
          {
            text: "Commerce réparation d'automobiles et de motocycles",
            value: "Commerce réparation d'automobiles et de motocycles",
          },
          {
            text: "Transports et entreposage",
            value: "Transports et entreposage",
          },
          {
            text: "Hébergement et restauration",
            value: "Hébergement et restauration",
          },
          {
            text: "Information et communication",
            value: "Information et communication",
          },
          {
            text: "Activités financières et d'assurance",
            value: "Activités financières et d'assurance",
          },
          { text: "Activités immobilières", value: "Activités immobilières" },
          {
            text: "Activités spécialisées, scientifiques et techniques",
            value: "Activités spécialisées, scientifiques et techniques",
          },
          {
            text: "Activités de services administratifs et de soutien",
            value: "Activités de services administratifs et de soutien",
          },
          { text: "Administration publique", value: "Administration publique" },
          { text: "Enseignement", value: "Enseignement" },
          {
            text: "Santé humaine et action sociale",
            value: "Santé humaine et action sociale",
          },
          {
            text: "Arts, spectacles et activités récréatives",
            value: "Arts, spectacles et activités récréatives",
          },
          {
            text: "Autres activités de services",
            value: "Autres activités de services",
          },
          {
            text: "Activités des ménages en tant qu'employeurs",
            value: "Activités des ménages en tant qu'employeurs",
          },
          {
            text: "Activités extra-territoriales",
            value: "Activités extra-territoriales",
          },
        ],
      },
      {
        label: "Code Postal",
        required: true,
        type: InputType.POSTAL,
        name: "postalCode",
        answer: "",
        summary_text: "Code Postal",
        backend_name: "postalCode",
      },
    ],
    prev: true,
    next: true,
    selectedStage: 15,
    number: 44,
    titleType: TitleType.COMPANY_INFO,
  },
];
