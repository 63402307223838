<nb-card *ngIf="!addComment && !showComments">
  <nb-card-header>
    <h5>{{ 'quotes.fields.quote' | translate }} {{quote.ref}}</h5>
    <div *ngIf="quote?.status >= 0" [class]="getStatusClass(quote?.status)">{{ getStatus(quote?.status) }}</div>
    <button nbButton ghost nbTooltip="Fermer" (click)="close()" class="close-button">
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <section>
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>
            <nb-icon class="list-icon" icon="person-outline"></nb-icon>{{ 'quotes.fields.user_info' | translate }}
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="image-container">
                <img src="./assets/images/pp.png" alt="">
              </div>
              <div class="user-data" *ngIf="quote?.customer?.lastName">
                <div class="row"><span>{{ 'quotes.fields.name' | translate }} :</span><span class="value">{{quote.customer.firstName}} {{quote.customer.lastName}}</span></div>
                <div class="row"><span>{{ 'quotes.fields.email' | translate }} :</span><span class="value">{{quote.customer.email}}</span></div>
                <div class="row"><span>{{ 'quotes.fields.phone' | translate }} :</span><span class="value">{{quote.customer.phone || '-'}}</span></div>
                <ng-container *ngIf="quote.customer.companyName">
                  <hr  class="user-line">
                  <div class="row"><span>{{ 'quotes.fields.companyName' | translate }} :</span><span class="value">{{quote.customer.companyName || '-'}}</span></div>
                  <div class="row"><span>{{ 'quotes.fields.job' | translate }} :</span><span class="value">{{quote.customer.job || '-'}}</span></div>
                  <div class="row"><span>{{ 'quotes.fields.businessSector' | translate }} :</span><span class="value">{{quote.customer.businessSector || '-'}}</span></div>
                  <div class="row"><span>{{ 'quotes.fields.city' | translate }} :</span><span class="value">{{quote.customer.postalCode || '-'}}</span></div>
                </ng-container>
              </div>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </section>
    <section>
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <nb-icon class="list-icon" icon="book-open-outline"></nb-icon> {{ 'quotes.fields.quote_info' | translate }}
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="user-data" *ngIf="quote?.customer?.lastName">
              <span class="name_field">{{ 'quotes.fields.reference' | translate }} : <span class="value">{{quote.ref}}</span></span>
              <span class="name_field">{{ 'quotes.fields.created' | translate }} : <span class="value">{{quote.createdAt |  date:"dd MMMM YYYY HH:mm":'':'fr-FR' }}</span></span>
              <span class="name_field">{{ 'quotes.fields.updated' | translate }} : <span class="value">{{quote.updatedAt |  date:"dd MMMM YYYY HH:mm":'':'fr-FR' }}</span></span>
                <nb-accordion *ngIf="quote.quoteResult?.total" class="table-accordion">
                  <nb-accordion-item>
                    <nb-accordion-item-header>
                      <span class="name_field">{{ 'quotes.fields.first_value' | translate }} : <span class="value">{{quote.quoteResult?.total | number:'1.0-2' || '-' }} %</span></span>
                    </nb-accordion-item-header>
                    <nb-accordion-item-body>
                      <table>
                        <thead>
                          <tr>
                            <td>Catégorie</td>
                            <td class="second-column">Gain</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let key of keysOfQuoteResult; index as i">
                            <td>{{ quoteResultNames[key] || '' }}</td>
                            <td class="second-column">{{ quote.quoteResult[key] }} %</td>
                          </tr>
                        </tbody>
                      </table>
                    </nb-accordion-item-body>
                  </nb-accordion-item>
                </nb-accordion>
                <span *ngIf="!quote.quoteResult?.total" class="name_field">{{ 'quotes.fields.first_value' | translate }} : <span class="value">{{quote.quoteResult || '-' }} %</span></span>
                
                <nb-accordion *ngIf="quote.quoteBudget?.total" class="table-accordion">
                  <nb-accordion-item>
                    <nb-accordion-item-header>
                      <span class="name_field">{{ 'quotes.fields.second_value' | translate }} :  <span class="value">{{quote.quoteBudget?.total | number:'1.0-2':'fr' || '-' }} €</span></span>
                    </nb-accordion-item-header>
                    <nb-accordion-item-body>
                      <nb-accordion *ngFor="let onePack of quote.quoteBudget?.packDetails; index as i" class="table-accordion">
                        <nb-accordion-item>
                          <nb-accordion-item-header>
                            <nb-icon class="list-icon" icon="corner-down-right-outline"></nb-icon> <span class="name_field">{{ onePack.packName }} (x{{onePack.quantity}}) : <span class="value">{{onePack.total | number:'1.0-2':'fr' || '-' }} €</span></span>
                          </nb-accordion-item-header>
                          <nb-accordion-item-body>
                            <table>
                              <thead>
                                <tr>
                                  <td class="first-column"></td>
                                  <td>Nombre</td>
                                  <td>Prix unitaire</td>
                                  <td>Prix total</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let key of getKeysOf(onePack.componentList); index as i">
                                  <td>{{ key }}</td>
                                  <td>{{ onePack.componentList[key] }}</td>
                                  <td>{{ onePack.componentPrices[key] }}</td>
                                  <td class="second-column">{{ (onePack.componentList[key] * onePack.componentPrices[key]) | number:'1.0-2':'fr' || '-' }} €</td>
                                </tr>
                              </tbody>
                            </table>
                          </nb-accordion-item-body>
                        </nb-accordion-item>
                      </nb-accordion>
                    </nb-accordion-item-body>
                  </nb-accordion-item>
                </nb-accordion>
                <span *ngIf="!quote.quoteBudget?.total" class="name_field">{{ 'quotes.fields.second_value' | translate }} :  <span class="value">{{quote.quoteBudget || '-' }} €</span></span>
            </div> 
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </section>
    <section>
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <div class="icon-container"><span class="icon-label">1</span> <nb-icon class="list-icon" icon="bookmark-outline"></nb-icon></div> {{ 'quotes.fields.first_questionnaire' | translate}}
            ({{quote?.surveyPercent?.surveyW | number}}%)
            <!-- <div class="progress-bar" [style]="getCircularStyle(quote?.surveyPercent?.surveyW)">
              <span class="progress-value">{{quote?.surveyPercent?.surveyW | number}}%</span>
            </div> -->
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="summary-table">
              <ng-container *ngFor="let step of quote?.surveyW">
                <div class="summary-one-table-entry one">
                  <div class="row-header">
                    {{ getEnergyName(step?.type) }}
                  </div>
                  <div class="row-values">
                    <ng-container *ngFor="let substep of step?.data">
                    <div class="one-row">
                      <span class="left-side" [innerHTML]="getQuestionText(substep?.name, step.type)"></span>
                      <span class="right-side" [innerHTML]="substep?.answer || getAnswer(substep?.value)" [title]="substep?.answer || getAnswer(substep?.value)"></span>
                    </div>
                  </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </section>
    <section>
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>
            <div class="icon-container"><span class="icon-label">2</span> <nb-icon class="list-icon" icon="bookmark-outline"></nb-icon></div> {{ 'quotes.fields.second_questionnaire' | translate}}
            ({{quote?.surveyPercent?.surveyB | number}}%)
            <!-- <div class="progress-bar" [style]="getCircularStyle(quote?.surveyPercent?.surveyB)">
              <span class="progress-value">{{quote?.surveyPercent?.surveyB | number}}%</span>
            </div> -->
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="summary-table">
              <ng-container *ngFor="let step of quote?.surveyB">
                <div class="summary-one-table-entry one">
                  <div class="row-header">
                    {{ getEnergyName(step?.type) }}
                  </div>
                  <div class="row-values">
                    <ng-container *ngFor="let substep of step?.data">
                    <div class="one-row">
                      <span class="left-side" [innerHTML]="getQuestionText(substep?.name, step.type)"></span>
                      <span class="right-side" [innerHTML]="substep?.answer || getAnswer(substep?.value)" [title]="substep?.answer || getAnswer(substep?.value)"></span>
                    </div>
                  </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </section>
    <section #comments id="comments">
      <nb-accordion>
        <nb-accordion-item [expanded]="showComments">
          <nb-accordion-item-header>
            <nb-icon class="list-icon" icon="message-square-outline"></nb-icon> {{ 'quotes.fields.comments' | translate }} {{quote.comments?.length ? '('+quote.comments.length+')' : '(0)'}}
          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="summary-table">
              <ng-container *ngFor="let comment of quote?.comments">
                <div class="summary-one-table-entry one comments-table">
                  <div class="row-values">
                    <div class="one-row">
                      <span class="left-side">{{ comment.date | date:"dd MMMM YYYY HH:mm":'':'fr-FR' || '-' }}</span>
                      <span class="right-side comment">{{ comment.body || '-' }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!addingMode" class="add-new-content">
              <button class="add-new-button" nbButton (click)="addingMode = !addingMode"><nb-icon class="list-icon" icon="plus-circle-outline"></nb-icon> {{ 'quotes.new_comment' | translate }}</button>
            </div>
            <div class="one-row success-container" *ngIf="success">
              <nb-icon class="list-icon" icon="checkmark-circle-2-outline"></nb-icon>
              <div class="error-text">
                {{ 'quotes.comment_success' | translate }}
              </div>
            </div>
            <div *ngIf="addingMode" class="adding-mode-container" [nbSpinner]="loading">
              <div class="one-row error-container" *ngIf="commentError">
                <nb-icon class="list-icon" icon="alert-circle-outline"></nb-icon>
                <div class="error-text">
                  {{ commentError | translate }}
                </div>
              </div>
              <div class="one-row">
                <span class="left-side">{{ 'quotes.comment_text' | translate }} :</span>
                <textarea nbInput fullWidth #commentText></textarea>
              </div>
              <div class="one-row">
                <button class="add-new-button cancel" nbButton (click)="addingMode = false"> {{ 'common.cancel' | translate }}</button>
                <button class="add-new-button" nbButton (click)="addNewComment()"><nb-icon class="list-icon" icon="upload-outline"></nb-icon> {{ 'quotes.add_comment' | translate }}</button>
              </div>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </section>
  </nb-card-body>
</nb-card>


<nb-card *ngIf="addComment" class="add_comment">
  <nb-card-header>
    <h5> {{ 'quotes.new_comment' | translate }}</h5>
    <button nbButton ghost nbTooltip="Fermer" (click)="close()" class="close-button">
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body [nbSpinner]="loading">
    <div class="adding-mode-container">
      <div class="one-row error-container" *ngIf="commentError">
        <nb-icon class="list-icon" icon="alert-circle-outline"></nb-icon>
        <div class="error-text">
          {{ commentError | translate }}
        </div>
      </div>
      <div class="one-row success-container" *ngIf="success">
        <nb-icon class="list-icon" icon="checkmark-circle-2-outline"></nb-icon>
        <div class="error-text">
          {{ 'quotes.comment_success' | translate }}
        </div>
      </div>
      <div class="one-row">
        <span class="left-side">{{ 'quotes.comment_text' | translate }} :</span>
        <textarea nbInput fullWidth #commentText></textarea>
      </div>
      <div class="one-row">
        <button class="add-new-button cancel" nbButton (click)="addingMode = false"> {{ 'common.cancel' | translate }}</button>
        <button class="add-new-button" nbButton (click)="addNewComment()"><nb-icon class="list-icon" icon="upload-outline"></nb-icon> {{ 'quotes.add_comment' | translate }}</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card *ngIf="showComments">
  <nb-card-header>
    <h5> {{ 'quotes.comments_list' | translate }}</h5>
    <button nbButton ghost nbTooltip="Fermer" (click)="close()" class="close-button">
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body [nbSpinner]="loading">
    <div class="summary-table">
      <ng-container *ngFor="let comment of quote?.comments">
        <div class="summary-one-table-entry one comments-table">
          <div class="row-values">
            <div class="one-row">
              <span class="left-side">{{ comment.date | date:"dd MMMM YYYY HH:mm":'':'fr-FR' || '-' }}</span>
              <span class="right-side comment">{{ comment.body || '-' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!addingMode" class="add-new-content">
      <button class="add-new-button" nbButton (click)="addingMode = !addingMode"><nb-icon class="list-icon" icon="plus-circle-outline"></nb-icon> {{ 'quotes.new_comment' | translate }}</button>
    </div>
    <div class="one-row success-container" *ngIf="success">
      <nb-icon class="list-icon" icon="checkmark-circle-2-outline"></nb-icon>
      <div class="error-text">
        {{ 'quotes.comment_success' | translate }}
      </div>
    </div>
    <div *ngIf="addingMode" class="adding-mode-container" [nbSpinner]="loading">
      <div class="one-row error-container" *ngIf="commentError">
        <nb-icon class="list-icon" icon="alert-circle-outline"></nb-icon>
        <div class="error-text">
          {{ commentError | translate }}
        </div>
      </div>
      <div class="one-row">
        <span class="left-side">{{ 'quotes.comment_text' | translate }} :</span>
        <textarea nbInput fullWidth #commentText></textarea>
      </div>
      <div class="one-row">
        <button class="add-new-button cancel" nbButton (click)="addingMode = false"> {{ 'common.cancel' | translate }}</button>
        <button class="add-new-button" nbButton (click)="addNewComment()"><nb-icon class="list-icon" icon="upload-outline"></nb-icon> {{ 'quotes.add_comment' | translate }}</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>