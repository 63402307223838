import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { QuoteStatusClass, QuoteStatusName, QuotesJson } from '../../../@types/QuotesJson';
import { QuotesService } from '../../../../app/services/quotes.service';
import { HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

export enum Questions {
  isHeatingScheduledBasedOnOccupancy = '"Le chauffage de votre bâtiment est-il programmé en fonction des horaires d\'occupation ?',
  isRoomByRoomHeatingScheduled = 'le chauffage de votre bâtiment est-il programmé en fonction des horaires d\'occupation pièce par pièce ?',
  isHeatingActivatedByPresenceSensors = 'Le chauffage est-il activé avec des détecteurs de présence ?',
  hasCentralizedHeatingManagementSolution = 'Disposez-vous d\’une solution de gestion technique centralisée* du chauffage ?',
  hasAirConditioningSystem = 'Avez-vous un système de climatisation ?',
  isAirConditioningScheduledBasedOnOccupancy = 'La climatisation de votre bâtiment est-elle programmée en fonction des horaires d\'occupation de chaque pièce ?',
  isAirConditioningActivatedByPresenceSensors = 'La climatisation est-elle activée avec des détecteurs de présence?',
  hasCentralizedAirConditioningManagementSolution = 'Disposez-vous d\'une solution de gestion technique centralisée de la climatisation ?',
  hasHotWater = 'Votre ou vos bâtiments ont-ils l\'eau chaude sanitaire ?',
  //  = 'Ont-ils des chauffe-eaux ?'
  isHeatingScheduledForHotWaterTank = 'Utilisez-vous un système de programmation horaire pour le chauffage de votre ballon d\'eau chaude',
  isHotWaterProductionOptimizedBasedOnTankTemperature = 'Votre production d\'eau chaude est-elle optimisée en fonction de la température du ballon',
  areLightsAutomaticallyTurnedOffAtNight = 'Est-ce que les éclairages de votre bâtiment sont automatiquement éteints la nuit ?',
  isLightingControllableBySchedule = 'Votre éclairage est-il pilotable...<br>...en fonction d\'un planning?',
  isLightingControllableByBrightness = '...en fonction de la luminosité ?',
  isLightingControllableByPresence = '...en fonction de la présence ?',
  isAwareOfEnergyConsumptionDistribution = 'Savez-vous comment sont réparties les consommations énergétiques de votre bâtiment ?',
  implementsCommunicationOnEnergyConsumptionForEmployees = 'Mettez-vous en place une communication sur vos consommations énergétiques à destination de vos collaborateurs ?',
  Boiler = 'Combien de chaudières avez-vous ?',
  RegulatedCircuits = 'Combien de circuits (ou de départs) avez-vous ?',
  EmittersHeatingVentilation = "Combien d'équipements de chauffage avez-vous ?",
  HeatPumpAirWater = "Nombre pièces chauffées ?",
  HeatPumpAirAir = "Nombre pièces chauffées ?",
  EmittersHeatPumpAirAir = "Nombre d'unités intérieures",
  ElectricRadiators = "Nombre de radiateurs électriques",
  HeatPumpAirAir_2 = "Nombre pièces climatisées par votre PAC Air/Air ?",
  HeatPumpAirWater_2 = "Nombre pièces climatisées par votre PAC Air/Eau ?",
  EmittersHeatPumpAirAir_2 = "Nombre d'unités intérieures pour votre Split indépendant ?",
  Ventilation = "Votre bâtiment dispose-t-il d'une Ventilation Mécanique Contrôlée ?",
  DHWExchange = "Quel est le type de production ?",
  Cumulus = "Combien avez-vous de cumulus ?",
  LightingZone = "Combien de zones sont présentes ?",
  CabinetLighting = "Combien avez-vous d'armoires électriques ?"
}

export const EnergyNames = {
  HeatingSystem: 'Chauffage',
  LightingSystem: 'Éclairage',
  HotWaterSystem: 'Eau chaude sanitaire',
  AirConditioning: 'Climatisation, refroidissement',
  EnergyConsumptionMonitoringAndCommunicationSystem: 'Consommation énergétique et communication',
  Heating: 'Chauffage',
  Lighting: 'Eclairage',
  DomesticHotWater: 'Eau chaude sanitaire',
  VMC: 'Ventilation Mécanique Contrôlée (VMC)',
};

export const QuoteResultNames = {
  AirConditioning: 'Climatisation',
  EnergyConsumptionMonitoringAndCommunicationSystem: 'Consommation énergétique et communication',
  HeatingSystem: 'Chauffage',
  HotWaterSystem: 'Eau chaude sanitaire',
  LightingSystem: 'Éclairage'
};

@Component({
  selector: 'gp-quotes-details-modal',
  templateUrl: './quote-details-modal.component.html',
  styleUrls: ['./quote-details-modal.component.scss'],
})
export class QuoteDetailsModalComponent implements OnInit {
  @ViewChild('commentText') commentText: ElementRef;
  @ViewChild('commentTitle') commentTitle: ElementRef;
  @ViewChild('comments') comments: ElementRef;
  @Input() quote: QuotesJson | null = null;
  @Input() headers: HttpHeaders;
  @Input() showComments: boolean;
  @Input() addComment: boolean;
  addingMode = false;
  commentError = null;
  success = false;
  loading = false;
  keysOfQuoteResult = Object.keys(QuoteResultNames);
  quoteResultNames = QuoteResultNames;

  constructor(
    protected ref: NbDialogRef<QuoteDetailsModalComponent>,
    private quotesService: QuotesService,
    private scroller: ViewportScroller) {}

  close() {
    this.ref.close({data: this.quote});
  }

  ngOnInit() {
    console.log("showComments?:", this.showComments);
    this.commentError = null;
    if (this.showComments) {
      this.scroller.scrollToAnchor('comments');
    }
  }

  getQuestionText(name: string, type: string): string {
    if ((name === 'HeatPumpAirAir' || name === 'HeatPumpAirWater' || name === 'EmittersHeatPumpAirAir') && type === 'AirConditioning') {
      name = `${name}_2`;
    }
    return name ? Questions[name] || '' : '';
  }

  getAnswer(answer: boolean | string | number): string | number {
    if (typeof answer === 'boolean') {
      return answer ? 'Oui' : 'Non';
    } else if (answer === 0) {
      return answer;
    } else {
      return answer || '';
    }
  }

  getKeysOf(objectName: any) {
    return objectName ? Object.keys(objectName) : [];
  }

  getEnergyName(name: string): string {
    return name ? EnergyNames[name] || '' : '';
  }

  getStatus(status: number) {
    if (status >= 0) {
      return QuoteStatusName[status];
    } else {
      return '-';
    }
  }

  getStatusClass(status: number) {
    if (status >= 0) {
      return `${QuoteStatusClass[status]} status`;
    } else {
      return 'status';
    }
  }

  getCircularStyle(value: number) {
    if (value) {
      return `background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#3366FF ${value}%, #edf1f7 0)`;
    } else if (value === 0) {
      return `background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#3366FF 0, #edf1f7 0)`;
    }
  }

  addNewComment() {
    this.commentError = null;
    this.loading = true;
    
    //check if title and text are filled - if no - error
    if (!this.commentText?.nativeElement?.value) {
      this.commentError = `quotes.error.comment_empty`;
      this.loading = false;
      return;
    }
    const comment = this.commentText?.nativeElement?.value;
    // send comment to backend - if something is wrong - error
    this.quotesService.addComment(this.quote._id, comment, this.headers).pipe(first()).subscribe(
      resp => {
        this.success = true;
        this.quote = resp;
        this.commentText.nativeElement.value = '';
        this.addingMode = false;
        this.loading = false;
        setTimeout(() => { 
          this.success = false;
        }, 5000);
      }, error => {
        console.error("Error while adding quote comment", error);
        this.commentError = `quotes.error.comment_serverside`;
        this.loading = false;
      }
    )
  }
}
